import { Container } from "react-bootstrap";
import Layout from "../../components/core/Layout";
import { PRESS } from "../../components/core/nav/NavigationItems";
import React from "react";
import { graphql } from "gatsby";

export default function NewsTemplate({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark;
  const updateDate = new Date(frontmatter.date).toLocaleDateString();

  return (
    <Layout page={PRESS}>
      <Container>
        <h2 className="text-uppercase h-muted">{updateDate}</h2>
        <h1 className="text-uppercase h bolder">{frontmatter.title}</h1>
        <div
          className="my-5"
          dangerouslySetInnerHTML={{ __html: html }}
          style={{ paddingTop: "2em" }}
        />
      </Container>
      {/*
      <Container style={{ clear: "both", paddingTop: "60px" }}>
        <h1 className="text-uppercase h pt-5">Kontakt</h1>
        <ContactForm />
      </Container>
      */}
    </Layout>
  );
}
export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        slug
        title
        category
        date
      }
    }
  }
`;
